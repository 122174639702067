import {useMutation, useQueryClient} from '@tanstack/react-query';
import SubscriptionsMethods from 'api/subscriptions/SubscriptionsMethods';

export function useMutationCancelSubscription(userId: number) {
    const queryClient = useQueryClient();

    const { mutate: cancelSubscription } = useMutation({
        mutationFn: () =>
            SubscriptionsMethods.deleteSubscription(userId),
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: [`subscriptions-${userId}`] });
        },
    });

    return { cancelSubscription };
}
