import { Link } from 'react-router-dom';

import avatar from 'assets/images/avatar.png';

import styles from './CatalogItem.module.scss';
import {UserDto} from "../../../../api/users/dto/user.dto";

interface CatalogItemProps {
  data: UserDto;
}

export default function CatalogItem({ data }: CatalogItemProps) {
    return (
    <div className={styles.card}>
      <div className={styles.card__userInfo}>
        <div className={styles.card__avatarWrapper}>
          <img className={styles.card__avatar} src={avatar} alt="avatar" />
        </div>
        <p>{data.name}</p>
      </div>
      <div className={styles.card__content}>
          {data.description}
      </div>
      <Link to={`/catalog/${data.id}`} className="link" />
    </div>
  );
}
