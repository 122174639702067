import { RoleEnum } from 'types/interfaces/role.enum';
import { useFetchMyProfile } from './query/useFetchMyProfile';

export const useHeaderRoutes = (pathname: string) => {
  const { user } = useFetchMyProfile();

  const checkDynamicRouteCatalog = /^\/catalog\/([\w-]+)$/;
  const checkDynamicRouteSetting = /^\/settings\/([\w-]+)$/;
  if (checkDynamicRouteCatalog.test(pathname)) {
    return {
      title: 'Chats',
      link: '/',
      isArrow: true,
      isActive: true,
    };
  }
  if (checkDynamicRouteSetting.test(pathname)) {
    return {
      title: 'Settings',
      link: '/settings',
      isArrow: true,
      isActive: true,
    };
  }

  switch (pathname) {
    case '/':
      return {
        title: 'Settings',
        link: '/settings',
        isArrow: false,
        isActive: true,
      };
    case '/settings':
    case '/catalog':
      return {
        title: 'Chats',
        link: '/',
        isArrow: true,
        isActive: user?.role === RoleEnum.USER ? true : false,
      };

    default:
      return { title: '', link: '', isArrow: false, isActive: false };
  }
};
