import { SVGProps } from 'react';

export default function LogoIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="40.000000"
      height="40.000000"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip56_4216">
          <rect
            id="logo"
            width="40.000000"
            height="40.000000"
            fill="white"
            fillOpacity="0"
          />
        </clipPath>
        <linearGradient
          x1="40.114243"
          y1="25.590914"
          x2="-2.139421"
          y2="17.011955"
          id="paint_linear_56_4206_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5E42D2" />
          <stop offset="1.000000" stopColor="#E2B9F9" />
        </linearGradient>
        <linearGradient
          x1="27.551617"
          y1="6.778935"
          x2="29.660885"
          y2="-0.126497"
          id="paint_linear_56_4207_0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F2E2F5" />
          <stop offset="1.000000" stopColor="#FFFFFF" />
        </linearGradient>
      </defs>
      <g clipPath="url(#clip56_4216)">
        <path
          id="path"
          d="M5.53 1.88L13.64 1.89L16.27 1.89C16.73 1.89 17.22 1.85 17.67 1.9C19.11 1.86 20.56 1.9 22.01 1.9L30.87 1.89C32.14 1.9 33.41 1.93 34.66 1.9C36.27 1.91 37.47 1.79 38.7 3.04C38.96 3.31 39.09 3.65 39.33 3.93C39.83 4.9 39.77 5.79 39.74 6.85C39.67 8.63 39.71 10.43 39.71 12.21L39.74 22.4L39.74 25.59C39.75 26.33 39.81 27.13 39.67 27.86C39.51 28.64 39.03 29.42 38.45 29.96C38.38 30.03 38.3 30.09 38.23 30.15C38.16 30.21 38.08 30.27 38 30.32C37.93 30.38 37.85 30.43 37.76 30.48C37.68 30.53 37.6 30.58 37.52 30.62C37.43 30.66 37.35 30.7 37.26 30.74C37.17 30.78 37.08 30.81 36.99 30.84C36.9 30.88 36.81 30.9 36.72 30.93C36.63 30.95 36.54 30.98 36.44 31C35.79 31.12 35.05 31.06 34.39 31.06L31.22 31.06L25.39 31.06C24.38 31.06 23.34 31.1 22.33 31.06C22.29 31.06 22.25 31.05 22.21 31.05C21.36 31.08 20.5 31.05 19.64 31.05L15.89 31.05C13.38 33.53 10.91 36.05 8.44 38.56C8.12 38.88 7.4 39.75 7.07 39.94C7 39.98 6.92 39.99 6.85 40C6.69 40 6.54 39.97 6.43 39.85C6.04 39.49 6.17 38.07 6.16 37.54C6.16 35.59 6.19 33.63 6.16 31.67L6.16 31.11C5.02 31.11 3.76 31.25 2.67 30.84C2.17 30.54 1.87 30.46 1.4 30.01C0.86 29.48 0.51 28.75 0.4 27.99C0.29 27.33 0.33 26.63 0.33 25.96L0.35 21.72L0.33 10.96C0.32 9.69 0.33 8.41 0.31 7.14C0.35 5.57 0.06 4.19 1.3 2.95C2.56 1.69 3.9 1.88 5.53 1.88Z"
          fill="url(#paint_linear_56_4206_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M17.68 1.93C19.12 1.89 20.57 1.92 22.01 1.92L30.88 1.92C32.14 1.92 33.41 1.95 34.67 1.92C36.27 1.93 37.47 1.82 38.7 3.06C38.96 3.33 39.1 3.67 39.33 3.95C39.83 4.92 39.77 5.81 39.74 6.87C39.35 7.21 38.94 7.51 38.53 7.84C38.22 8.09 37.93 8.36 37.6 8.59C37.6 7.8 37.6 7 37.61 6.21C37.61 5.83 37.65 5.41 37.6 5.03C37.56 4.79 37.49 4.56 37.36 4.35C37.1 3.94 36.72 3.77 36.27 3.67C35.98 3.59 35.23 3.65 34.89 3.65L31.61 3.66L19.81 3.65L15.76 3.65C15.04 3.65 14.32 3.67 13.6 3.65L13.62 3.65L13.59 3.62C13.94 3.64 17 3.66 17.1 3.62C17.13 3.61 17.14 3.59 17.17 3.57C17.17 3.5 17.17 3.44 17.19 3.37C17.31 3.3 17.4 3.19 17.45 3.06C17.45 2.87 17.51 2.86 17.42 2.7C17.36 2.58 17.07 2.17 16.98 2.07C16.83 1.89 14.39 1.96 13.99 1.96C15.18 1.86 16.46 2.04 17.64 1.93L17.68 1.93Z"
          fill="url(#paint_linear_56_4207_0)"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M8.12 36.21C8.15 34.78 8.12 33.36 8.12 31.94L8.13 30.25C8.13 29.97 8.15 29.68 8.13 29.41C8.12 29.25 8.04 29.19 7.91 29.15C7.65 29.07 7.3 29.12 7.03 29.12L5.15 29.13C4.67 29.13 4.13 29.18 3.67 29.07C3.37 29 3.08 28.85 2.85 28.65C2.48 28.33 2.31 27.91 2.28 27.43C2.25 27.01 2.27 26.58 2.27 26.17L2.28 23.87L2.3 16.69C2.28 14.04 2.27 11.4 2.28 8.75L2.27 6.48C2.27 6.07 2.24 5.65 2.28 5.25C2.31 4.87 2.45 4.51 2.7 4.23C2.95 3.97 3.36 3.76 3.72 3.71C4.03 3.67 4.35 3.69 4.66 3.69L6.15 3.69L11.39 3.69L28.08 3.68L33.45 3.68C34.38 3.68 35.33 3.73 36.25 3.67L36.29 3.67C36.74 3.77 37.11 3.94 37.37 4.35C37.5 4.56 37.58 4.79 37.61 5.03C37.66 5.41 37.62 5.83 37.62 6.22C37.62 7.01 37.62 7.8 37.62 8.59L37.63 22.08L37.63 25.91C37.64 26.56 37.68 27.25 37.61 27.9C37.58 28.19 37.5 28.37 37.29 28.58C37.09 28.79 36.83 28.94 36.54 28.99C36.11 29.07 35.63 29.03 35.19 29.03L33.11 29.03L25.1 29.03L23.21 29.02C22.94 29.02 22.61 28.99 22.34 29.03C21.08 29 19.8 29.03 18.54 29.04C17.49 29.04 16.43 29.03 15.38 29.06C13.93 30.34 12.59 31.75 11.23 33.13C10.2 34.16 9.16 35.19 8.12 36.21Z"
          fill="#7B2CBF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M5.79 28.48C5.29 28.54 3.91 28.63 3.47 28.41C3.22 28.29 2.99 28.04 2.91 27.77C2.76 27.26 2.84 25.77 2.84 25.19L2.84 20.26L2.84 10.88C2.84 9.13 2.78 7.37 2.83 5.62C2.83 5.4 2.85 5 2.97 4.82C3.12 4.59 3.44 4.33 3.71 4.29C4.05 4.23 4.43 4.26 4.77 4.26L6.63 4.26L13.45 4.26L28.23 4.26L33.47 4.26C34.23 4.27 34.98 4.24 35.73 4.26C36.03 4.26 36.34 4.32 36.58 4.51C36.8 4.68 36.95 4.91 36.99 5.19C37.04 5.58 37.04 8.86 36.97 9.08C36.96 9.11 36.91 9.16 36.89 9.19C36.56 9.39 36.25 9.72 35.95 9.97C35.43 10.41 34.91 10.84 34.38 11.27C33.18 12.25 31.96 13.19 30.75 14.15C29.41 15.22 27.98 16.38 26.55 17.3L26.54 21.45C26.54 21.97 26.6 22.9 26.52 23.37C26.52 23.58 26.42 23.78 26.26 23.92C26.11 24.07 25.93 24.11 25.73 24.13C25.36 24.17 24.99 24.15 24.62 24.15L22.66 24.15L16.49 24.15L16.31 24.15C13.84 25.69 10.95 26.87 8.18 27.77C7.39 28.03 6.61 28.28 5.79 28.48Z"
          fill="#9D4EDD"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M7.1 11.01C7.32 11 7.5 11.05 7.68 11.17C7.91 11.32 8.06 11.57 8.11 11.84C8.11 11.88 8.12 11.91 8.12 11.94C8.12 11.98 8.12 12.01 8.12 12.05C8.12 12.08 8.12 12.12 8.11 12.15C8.11 12.19 8.1 12.22 8.09 12.26C8.08 12.29 8.07 12.32 8.06 12.36C8.05 12.39 8.04 12.42 8.03 12.45C8.01 12.49 8 12.52 7.98 12.55C7.96 12.58 7.94 12.61 7.92 12.64C7.76 12.86 7.52 12.99 7.26 13.04C7.02 13.02 6.82 13 6.62 12.86C6.59 12.84 6.56 12.82 6.53 12.79C6.51 12.77 6.48 12.75 6.46 12.72C6.43 12.7 6.41 12.67 6.39 12.65C6.37 12.62 6.35 12.59 6.33 12.56C6.31 12.53 6.29 12.5 6.28 12.47C6.26 12.44 6.25 12.41 6.24 12.38C6.22 12.34 6.21 12.31 6.2 12.28C6.2 12.25 6.19 12.21 6.18 12.18C6.14 11.91 6.19 11.62 6.35 11.41C6.54 11.14 6.79 11.06 7.1 11.01Z"
          fill="#C77DFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M6.91 6.37C7.13 6.34 7.31 6.36 7.51 6.47C7.87 6.65 8.09 7.01 8.21 7.38C8.42 8.01 8.41 8.76 8.11 9.36C7.93 9.73 7.67 9.94 7.28 10.07C7.11 10.08 6.97 10.08 6.81 10.02C6.47 9.89 6.23 9.61 6.07 9.28C6.06 9.24 6.04 9.21 6.03 9.17C6.01 9.13 6 9.09 5.98 9.06C5.97 9.02 5.96 8.98 5.95 8.94C5.94 8.9 5.93 8.86 5.92 8.82C5.91 8.79 5.9 8.75 5.89 8.71C5.89 8.67 5.88 8.63 5.88 8.59C5.87 8.55 5.87 8.51 5.86 8.47C5.86 8.43 5.86 8.39 5.86 8.35C5.85 8.31 5.85 8.27 5.85 8.23C5.86 8.19 5.86 8.15 5.86 8.11C5.86 8.07 5.87 8.03 5.87 7.99C5.87 7.95 5.88 7.91 5.89 7.87C5.89 7.83 5.9 7.79 5.91 7.75C5.92 7.71 5.93 7.67 5.94 7.63C5.95 7.59 5.96 7.56 5.97 7.52C5.98 7.48 6 7.44 6.01 7.4C6.18 6.94 6.45 6.58 6.91 6.37Z"
          fill="#C77DFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M15.67 14.23C15.67 13.16 15.54 11.58 15.89 10.62C16.29 9.53 17.09 8.68 18.15 8.21C19.15 7.75 20.37 7.73 21.39 8.13C22.46 8.53 23.39 9.41 23.86 10.46C24.05 10.87 24.22 11.38 24.22 11.84C24.32 12.54 24.23 13.48 24.24 14.21C24.84 14.21 25.89 14.08 26.34 14.54C26.44 14.65 26.51 14.79 26.54 14.94C26.65 15.42 26.57 16.71 26.57 17.28L26.56 21.43C26.56 21.96 26.62 22.89 26.55 23.35C26.54 23.56 26.44 23.76 26.28 23.91C26.13 24.05 25.96 24.09 25.75 24.11C25.39 24.15 25.01 24.13 24.64 24.13L22.68 24.13L16.51 24.13L16.34 24.13C15.7 24.1 14.14 24.26 13.69 23.83C13.5 23.65 13.42 23.41 13.41 23.15C13.38 22.61 13.4 22.05 13.41 21.51L13.41 18.64L13.42 16.32C13.42 15.89 13.4 15.43 13.45 15C13.47 14.81 13.56 14.62 13.69 14.48C14.06 14.1 15.16 14.23 15.67 14.23Z"
          fill="#3C096C"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M18.69 9.41C19.15 9.22 19.98 9.07 20.45 9.27C20.51 9.29 20.52 9.31 20.54 9.37C20.21 9.32 19.89 9.31 19.56 9.34C18.77 9.51 18.14 9.88 17.69 10.56C17.21 11.3 17.27 12.04 17.28 12.89C17.28 13.34 17.27 13.8 17.29 14.25C17.17 14.25 17 14.26 16.91 14.2C16.84 13.82 16.87 11.92 16.93 11.5C16.97 11.25 17.06 11.02 17.16 10.79C17.49 10.13 17.98 9.64 18.69 9.41Z"
          fill="#050038"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M19.32 20.67C18.94 20.48 18.63 20.25 18.4 19.88C18.11 19.42 18.01 18.91 18.13 18.38C18.25 17.88 18.54 17.49 18.98 17.22C19.4 16.97 19.91 16.88 20.38 17C20.86 17.13 21.25 17.47 21.49 17.9C21.75 18.35 21.82 18.87 21.68 19.37C21.51 19.98 21.12 20.36 20.58 20.65C20.57 20.98 20.57 21.32 20.6 21.65C20.65 21.9 20.72 22.14 20.8 22.39C20.31 22.39 19.74 22.34 19.26 22.42C19.17 22.41 19.12 22.42 19.05 22.36C19.24 21.79 19.29 21.27 19.32 20.67Z"
          fill="#E0AAFF"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
        <path
          id="path"
          d="M19.56 9.31C19.9 9.28 20.22 9.29 20.55 9.33C21.37 9.62 22.04 10 22.42 10.81C22.47 10.92 22.51 11.02 22.55 11.13C22.58 11.24 22.61 11.35 22.62 11.46C22.72 12.04 22.7 13.6 22.64 14.2C20.85 14.22 19.06 14.22 17.28 14.2C17.25 13.75 17.27 13.29 17.26 12.84C17.26 12 17.2 11.26 17.69 10.53C18.14 9.84 18.77 9.48 19.56 9.31Z"
          fill="#9D4EDD"
          fillOpacity="1.000000"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}
