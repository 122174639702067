import { useNavigate } from 'react-router-dom';

import UploadsAudioAiStudy from 'components/UploadsAudioAiStudy';
import { SignUpTabsType } from 'pages/SignUpBloggerPage/SignUpBloggerPage';
import SignUpFooter from '../SignUpFooter';

import styles from './BloggerAudioAi.module.scss';

export default function BloggerAudioAi() {
  const navigate = useNavigate();
  const handleTest = () => {
    navigate('/settings');
  };

  return (
    <>
      <h2 className={styles.title}>Customize your AI</h2>
      <div className={styles.content}>
        <UploadsAudioAiStudy />
      </div>
      <SignUpFooter onClick={handleTest} text='Finish'/>
    </>
  );
}
